let config = {
    s3: {
        REGION: "us-west-2",
        BUCKET: "forwood-qberisktoesg-prod-forwoodid",
        BATCH_PROCESS_BUCKET_NAME: "forwood-qberisktoesg-prod-forwoodid-batch-user-process"
    },
    appSyncAPI: {
        REGION: "us-west-2",
        URL: "https://ceoxxjslmnbsjfcogyeoct6mdq.appsync-api.us-west-2.amazonaws.com/graphql",
    },
    apiGateway: {
        REGION: "us-west-2",
        URL: "https://2nz3piy2o2.execute-api.us-west-2.amazonaws.com/env",
        USER_TOKEN_URL: "https://8h34u895yd.execute-api.us-west-2.amazonaws.com/prod"
    },
    configurationService: {
        URL: "https://config.qberisktoesg.forwoodsafety.com",
        WEBSOCKET: "wss://bqgustspmh.execute-api.us-west-2.amazonaws.com/prod"
    },
    cognito: {
        REGION: "us-west-2",
        USER_POOL_ID: "us-west-2_xIsrHLoHq",
        IDENTITY_POOL_ID: "us-west-2:df616a7b-88e5-456d-91df-de474ad8bdec",
        USERPOOL_HOSTED_DOMAIN: "forwood-qberisktoesg-id-prod",
        USERPOOL_ADFS_CALLBACK_URL: "https://id.qberisktoesg.forwoodsafety.com",
        USERPOOL_ADFS_LOGOUT_URL: "https://id.qberisktoesg.forwoodsafety.com",
        AUTHENTICATED_ROLE_ARN: "arn:aws:iam::975186682358:role/prod-CA"
    },
    samlProvider: {
      NAME: '',
      SUPPORTED_LIST: 'ForwoodAzure'
    },
    reactApp: {
        VERSION: "1.35.5",
        HOSTNAME: "id.qberisktoesg.forwoodsafety.com",
        ENV_NAME: "prod",
        COOKIE_DOMAIN: ".qberisktoesg.forwoodsafety.com",
        SECURE_PROTOCOL: true,
        FORWOOD_COMPANY_ID: "4a079be2-5e6c-474a-9191-a7bccb5aa945",
        FORWOOD_HOMEPAGE_MICROFRONTEND_URL: "https://homepage.qberisktoesg.forwoodsafety.com"
    },
    USERPOOL_ADFS_LAUNCH_URL(clientId) {
        return 'https://'+this.cognito.USERPOOL_HOSTED_DOMAIN+'.auth.'+this.cognito.REGION+'.amazoncognito.com/authorize?redirect_uri='+this.cognito.USERPOOL_ADFS_CALLBACK_URL+'&response_type=code&client_id='+clientId;
    },
};

export default config;
